/*** IMPORTS FROM imports-loader ***/


import "Flot";
import "Flot/jquery.flot.time.js";
import "zeroclipboard";

import angular from "angular";
import "angular-cookies";
import "angular-resource";
import "angular-sanitize";
import "angular-route";
import "angular-bootstrap";
import "angular-percentage-filter";
import "ng-clip";
import "angular-flot";
import "ng-table";
import "angular-validation-match";
import "bootstrap";
import "bootstrap-select";
import "ng-bootstrap-select/src/ng-bootstrap-select.js";
import "../lib/angular-datepicker";

export default angular.module('adhesiveApp', [
    'ngCookies',
    'ngResource',
    'ngSanitize',
    'ngRoute',
    'ui.bootstrap',
    'percentage',
    'ngClipboard',
    'angular-flot',
    'percentage',
    'datePicker',
    'ngTable',
    'validation.match',
    'ng-bootstrap-select'
  ])
  .constant('apiPrefixUrl', '/api/v1')
  .constant('newApiPrefixUrl', '/v2/api')
  .constant('advertiserPrefixUrl', '/s/advertiser')
  .constant('excelPrefixUrl', '/s/excel')
  .constant('userPrefixUrl', '/s/user')
  .constant('xandrPrefixUrl', '/s/xandr')
  .constant('billingPrefixUrl', '/s/billing')
  .constant('lineitemPrefixUrl', '/s/lineitem')
  .constant('newFrontendPrefix', '/fv2/')

  .constant('USER_TYPES', [
      {
          "label": "Super Admin",
          "value": 1
      },
      {
          "label": "Admin",
          "value": 2
      },
      {
          "label": "Salesman",
          "value": 3
      },
      {
          "label": "Advertiser",
          "value": 4
      }
  ])

  .constant('PAYMENT_AMOUNTS', [
      1.00,
      250.00,
      500.00,
      1000.00,
      1500.00,
      2000.00,
      2500.00,
      3000.00,
      5000.00,
      10000.00,
      15000.00,
      20000.00,
  //    25000.00,
  //    50000.00
  ])
  .constant('PRICING_MODELS', {
      1: 'CPM',
      2: 'CPA'
  })
  .constant('ERROR_MESSAGES',{
    all: 'Please correct all above errors before submiting',
    email:{
      required : 'Please enter valid email address',
      email: 'Please enter valid email address'
    },
    name:{
      required: 'Please enter website name',
      pattern: 'Please enter your website URL without the www or http preface!'
    },
    segment:{
      required: 'Please choose segment'
    },
    password:{
      required: 'Please enter password',
      minlength: 'Password must be at least 6 characters long'
    },
    confPass:{
      minlength: 'Password must be at least 6 characters long',
      match: 'Passwords must match!'
    }
  })
  .config(['$locationProvider', function($locationProvider) {
    $locationProvider.hashPrefix('');
  }])
  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider
      .when('/', {
        templateUrl: '/views/analytics.html',
        controller: 'AnalyticsCtrl',
        reloadOnSearch: true
      })
      .when('/admin', {
        templateUrl: '/views/admin/home/main.html',
        controller: 'AdminCtrl',
        reloadOnSearch: true
      })
      .when('/admin/segments', {
        templateUrl: '/views/admin/segments.html',
        controller: 'SegmentsCtrl',
        reloadOnSearch: false
      })
      .when('/admin/forecasting', {
        templateUrl: '/views/admin/forecasting/forecasting.html',
        controller: 'ForecastingCtrl',
        controllerAs: 'vm',
        reloadOnSearch: false
      })
      .when('/admin/reports-by-sales-rep', {
        template: '<reports-by-sales-rep-ctrl></reports-by-sales-rep-ctrl>',
        reloadOnSearch: false
      })
      .when('/old/admin/reports-by-sales-rep', {
        template: '<old-reports-by-sales-rep-ctrl></old-reports-by-sales-rep-ctrl>',
        reloadOnSearch: false
      })
      .when('/admin/monthly-reports-by-adv', {
        template: '<monthly-reports-by-adv-ctrl></monthly-reports-by-adv-ctrl>',
        reloadOnSearch: false
      })
      .when('/admin/custom_segments', {
        templateUrl: '/views/admin/custom-segments.html',
        controller: 'CustomSegmentsCtrl',
        reloadOnSearch: false
      })
      .when('/admin/missed-cap-report', {
        template: '<missed-cap-report-ctrl></missed-cap-report-ctrl>',
        reloadOnSearch: false
      })
      .when('/admin/creative-reports', {
        template: '<creative-reports></creative-reports>',
        reloadOnSearch: false
      })
      .when('/admin/tags-monitoring', {
        template: '<tags-monitoring></tags-monitoring>',
        reloadOnSearch: false
      })
      .when('/admin/health-report', {
        template: '<account-health-report></account-health-report>',
        reloadOnSearch: false
      })
      .when('/admin/new-advertisers', {
        templateUrl: '/views/admin/new-advertisers.html',
        controller: 'NewAdvertisersCtrl',
        reloadOnSearch: false
      })
      .when('/admin/campaigns/:userid', {
        templateUrl: '/views/admin/campaigns.html',
        controller: 'CampaignsCtrl'
      })
      .when('/admin/campaigns/:userid/:website_uuid', {
        templateUrl: '/views/admin/campaigns.html',
        controller: 'CampaignsCtrl'
      })
      .when('/admin/revenue', {
        templateUrl: '/views/admin/revenue.html',
        controller: 'RevenueCtrl',
        reloadOnSearch: false
      })
      .when('/old/admin/revenue', {
        templateUrl: '/views/admin/old_revenue.html',
        controller: 'RevenueOldCtrl',
        reloadOnSearch: false
      })

      .when('/admin/newbilling', {
        templateUrl: '/views/admin/newbilling.html',
        controller: 'NewBillingCtrl',
        reloadOnSearch: false
      })
      .when('/admin/report_requests', {
        templateUrl: '/views/admin/report_requests.html',
        controller: 'ReporRequestsCtrl',
        reloadOnSearch: false
      })
      .when('/billing', {
        templateUrl: '/views/billing/main.html',
        controller: 'BillingCtrl'
      })
      .when('/notifications', {
        templateUrl: '/views/admin/notifications.html',
        controller: 'NotificationsCtrl'
      })
      .when('/tags', {
        templateUrl: '/views/tags/main.html',
        controller: 'TagsCtrl'
      })
      .when('/campaigns', {
        templateUrl: '/views/campaigns.html',
        controller: 'StatisticCtrl',
        controllerAs: 'ctrl'
      })
      .when('/account', {
        templateUrl: '/views/acc-management.html',
        controller: 'AccManagementCtrl'
      })
      .when('/conversions', {
        templateUrl: '/views/conversions.html',
        controller: 'ConversionsListCtrl'
      })
      .when('/creatives', {
        templateUrl: '/views/creatives.html',
        controller: 'CreativesReportCtrl'
      })
      .when('/sites/:siteid', {
        templateUrl: '/views/site-management.html',
        controller: 'SiteManagementCtrl'
      })
      .when('/billing/deposit', {
        templateUrl: '/views/billing/deposit.html',
        controller: 'DepositCtrl',
        activetab: 'billing'
      })
      .when('/login', {
        templateUrl: '/views/admin/home/main.html',
        controller: 'AdminCtrl',
        reloadOnSearch: true
      })
      .when('/admin/segments', {
        templateUrl: '/views/admin/segments.html',
        controller: 'SegmentsCtrl',
        reloadOnSearch: false
      })
      .when('/admin/forecasting', {
        templateUrl: '/views/admin/forecasting/forecasting.html',
        controller: 'ForecastingCtrl',
        controllerAs: 'vm',
        reloadOnSearch: false
      })
      .when('/admin/reports-by-sales-rep', {
        template: '<reports-by-sales-rep-ctrl></reports-by-sales-rep-ctrl>',
        reloadOnSearch: false
      })
      .when('/old/admin/reports-by-sales-rep', {
        template: '<old-reports-by-sales-rep-ctrl></old-reports-by-sales-rep-ctrl>',
        reloadOnSearch: false
      })
      .when('/admin/monthly-reports-by-adv', {
        template: '<monthly-reports-by-adv-ctrl></monthly-reports-by-adv-ctrl>',
        reloadOnSearch: false
      })
      .when('/admin/custom_segments', {
        templateUrl: '/views/admin/custom-segments.html',
        controller: 'CustomSegmentsCtrl',
        reloadOnSearch: false
      })
      .when('/admin/missed-cap-report', {
        template: '<missed-cap-report-ctrl></missed-cap-report-ctrl>',
        reloadOnSearch: false
      })
      .when('/admin/creative-reports', {
        template: '<creative-reports></creative-reports>',
        reloadOnSearch: false
      })
      .when('/admin/tags-monitoring', {
        template: '<tags-monitoring></tags-monitoring>',
        reloadOnSearch: false
      })
      .when('/admin/health-report', {
        template: '<account-health-report></account-health-report>',
        reloadOnSearch: false
      })
      .when('/admin/new-advertisers', {
        templateUrl: '/views/admin/new-advertisers.html',
        controller: 'NewAdvertisersCtrl',
        reloadOnSearch: false
      })
      .when('/admin/campaigns/:userid', {
        templateUrl: '/views/admin/campaigns.html',
        controller: 'CampaignsCtrl'
      })
      .when('/admin/campaigns/:userid/:website_uuid', {
        templateUrl: '/views/admin/campaigns.html',
        controller: 'CampaignsCtrl'
      })
      .when('/admin/revenue', {
        templateUrl: '/views/admin/revenue.html',
        controller: 'RevenueCtrl',
        reloadOnSearch: false
      })
      .when('/old/admin/revenue', {
        templateUrl: '/views/admin/old_revenue.html',
        controller: 'RevenueOldCtrl',
        reloadOnSearch: false
      })

      .when('/admin/newbilling', {
        templateUrl: '/views/admin/newbilling.html',
        controller: 'NewBillingCtrl',
        reloadOnSearch: false
      })
      .when('/admin/report_requests', {
        templateUrl: '/views/admin/report_requests.html',
        controller: 'ReporRequestsCtrl',
        reloadOnSearch: false
      })
      .when('/billing', {
        templateUrl: '/views/billing/main.html',
        controller: 'BillingCtrl'
      })
      .when('/notifications', {
        templateUrl: '/views/admin/notifications.html',
        controller: 'NotificationsCtrl'
      })
      .when('/tags', {
        templateUrl: '/views/tags/main.html',
        controller: 'TagsCtrl'
      })
      .when('/campaigns', {
        templateUrl: '/views/campaigns.html',
        controller: 'StatisticCtrl',
        controllerAs: 'ctrl'
      })
      .when('/account', {
        templateUrl: '/views/acc-management.html',
        controller: 'AccManagementCtrl'
      })
      .when('/conversions', {
        templateUrl: '/views/conversions.html',
        controller: 'ConversionsListCtrl'
      })
      .when('/creatives', {
        templateUrl: '/views/creatives.html',
        controller: 'CreativesReportCtrl'
      })
      .when('/sites/:siteid', {
        templateUrl: '/views/site-management.html',
        controller: 'SiteManagementCtrl'
      })
      .when('/billing/deposit', {
        templateUrl: '/views/billing/deposit.html',
        controller: 'DepositCtrl',
        activetab: 'billing'
      })
      .when('/login', {
        templateUrl: '/views/login.html',
        controller: 'LoginCtrl'
      })
      .when('/register', {
        templateUrl: '/views/register.html',
        controller: 'RegisterCtrl'
      })
      .when('/reset_password', {
        templateUrl: '/views/reset_password.html',
        controller: 'ResetPassCtrl'
      })
      .when('/login/:userid', {
        templateUrl: '/views/login.html',
        controller: 'LoginCtrl'
      })
      .when('/shopify/landingpage', {
        templateUrl: '/views/shopify.html',
        controller: 'ShopifyCtrl',
        controllerAs: 'ctrl'
      })
      .when('/register', {
        templateUrl: '/views/register.html',
        controller: 'RegisterCtrl'
      })
      .when('/reset_password', {
        templateUrl: '/views/reset_password.html',
        controller: 'ResetPassCtrl'
      })
      .when('/login/:userid', {
        templateUrl: '/views/login.html',
        controller: 'LoginCtrl'
      })
      .when('/shopify/landingpage', {
        templateUrl: '/views/shopify.html',
        controller: 'ShopifyCtrl',
        controllerAs: 'ctrl'
      })
      .when('/v2/advertisers', {
        controller: 'NfAdvertisersCtrl',
        templateUrl: '/views/newfront.html',
      })
      .when('/v2/features', {
        controller: 'NfFeaturesCtrl',
        templateUrl: '/views/newfront.html',
      })
      .otherwise({
        redirectTo: '/'
      });
  }])
  .config(['$httpProvider', 'ngClipProvider', function($httpProvider, ngClipProvider){
      $httpProvider.interceptors.push(['$rootScope', '$q', 'userServ',
          function($rootScope, $q, userServ){
              return {
                  request: function(config){
                      // token passing through headers
                      config.headers = config.headers || {};
                      if(userServ.getToken() && !config.headers.Authorization){
                          config.headers.Authorization = 'Token ' + userServ.getToken();
                      }
                      return config;
                  },
                  responseError: function(rejection){
                      if(rejection.status == 401){
                          // handle the case where the user is not authenticated
                          $rootScope.$broadcast('loginRequired');
                      }

                      return $q.reject(rejection);
                  }
              };
          }]);
      ngClipProvider.setPath("/views/tags/ZeroClipboard.swf");
    }])
  .run(['$rootScope', '$location', 'userServ', 'WaterJwt',
      function($rootScope, $location, userServ, WaterJwt){
          // login required handling
          WaterJwt.get();
          $rootScope.$on('loginRequired', function(){

              // clear user data service
              userServ.unregister();

              // redirecting
              if($location.path() != '/login' &&  $location.path() != '/register' && $location.path() != '/reset_password' && $location.path() != '/shopify/landingpage'){
                  $location.path('/login');
              }
          });
      }]);



